import { ReactElement, ReactNode } from 'react';

export type InfoDisplayProps = {
  main: {
    text: string | number | ReactNode;
    postFix?: string;
    className?: string;
    postFixClassName?: string;
  };
  title?: string;
  icon?: ReactElement;
  className?: string;
  textContainerClassName?: string;
  titleStyle?: string;
};

export const InfoDisplay = ({
  main: {
    text,
    postFix,
    className: mainTextClassName = '',
    postFixClassName = '',
  },
  title = '',
  icon,
  className = '',
  textContainerClassName = '',
  titleStyle,
}: InfoDisplayProps) => {
  return (
    <div className={`flex flex-col ${className}`}>
      <div
        className={
          titleStyle ||
          'flex items-center text-subtitle16px font-light text-neutral-800'
        }
        data-class="info-title"
      >
        {title}
        {!!icon && <div className="ml-[0.25rem]">{icon}</div>}
      </div>
      <div className={`flex items-center ${textContainerClassName}`}>
        <div
          className={`${
            mainTextClassName || 'text-body1Medium text-neutral-900'
          }`}
        >
          {text}
        </div>
        {postFix && (
          <div
            className={`pl-[0.25rem] text-body1Light text-neutral-600 ${postFixClassName}`}
            data-class="info-postfix"
          >
            {postFix}
          </div>
        )}
      </div>
    </div>
  );
};
