import {
  EMAIL_REGEXP,
  PASSWORD_REGEXP,
  US_ZIP_CODE_REGEXP,
} from '../constants';

export const validatePassword = (password = '') => {
  return PASSWORD_REGEXP.test(password) && password.length >= 8;
};

export const validateEmail = (email = '') => {
  return EMAIL_REGEXP.test(email);
};

export const validateUSZipCode = (zipCode = '') => {
  return US_ZIP_CODE_REGEXP.test(zipCode);
};
